import accordion from "./modules/accordion";
import popup from "./modules/popup";
import select from "./modules/select";
import form from "./modules/form";

window.addEventListener('DOMContentLoaded', () => {
    try {
        accordion('.faq__accordion-item', '.faq__accordion-content', 'rotate-arrow');
    } catch (error) { }

    try {
        popup('[data-modal=order-verification]', '#order-verification', '.modal-verification__close');
    } catch (error) { }

    try {
        popup('[data-modal=order-replacement]', '#order-replacement', '.modal-order__close');
    } catch (error) { }

    try {
        popup('[data-modal=order-installation]', '#order-installation', '.modal-order__close');
    } catch (error) { }

    try {
        popup('[data-modal=order-plumbing]', '#order-plumbing', '.modal-order__close');
    } catch (error) { }

    try {
        popup('[data-modal=details-verification]', '#details-verification', '.modal-details__close');
    } catch (error) { }

        try {
        popup('[data-modal=details-replacement]', '#details-replacement', '.modal-details__close');
    } catch (error) { }

    try {
        popup('[data-modal=details-installation]', '#details-installation', '.modal-details__close');
    } catch (error) { }

    try {
        popup('[data-modal=details-plumbing]', '#details-plumbing', '.modal-details__close');
    } catch (error) { }

    try {
        popup('[data-modal=callback]', '#order-callback', '.modal-details__close');
    } catch (error) { }

    try {
        popup('', '#thanks', '.modal-details__close');
    } catch (error) { }

    try {
        select();
    } catch (error) { }

    try {
        // form('https://tr-70.ru/wp-content/themes/cubometr/mailer/smart.php');
        form('mailer/smart.php');
    } catch (error) { }

});

