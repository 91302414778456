import $ from 'jquery';

const form = (url) => {

    $('form').submit(function (e) {
        e.preventDefault();

        const formClasses = $(this).attr('class');
    
        if (formClasses.includes('modal-verification')) {
            $('.modal-verification').fadeOut('slow'); 
        } 
        if (formClasses.includes('modal-order')) {
            $('.modal-order').fadeOut('slow'); 
        }

        $('.overlay').fadeIn('slow');
        $('.loader').fadeIn('slow');

        $.ajax({
            type: 'POST',
            url: url,
            data: $(this).serialize()
        }).done(function () {
            $('.loader').fadeOut('slow')
            $(this).find('input').val('');
            $('#thanks').fadeIn('slow');
            $('form').trigger('reset');

            setTimeout(function() {
                $('.overlay').fadeOut('slow');
                $('#thanks').fadeOut('slow');
            }, 2000);

        });
        return false;
    });

};

export default form;