import $ from 'jquery';

const popup = (data, modal, close) => {
    $(data).on('click', function () {
        $(modal).fadeIn('slow');
        $('.overlay').fadeIn('slow');
    });

    $(close).on('click', function () {
        $(modal).fadeOut('slow');
        $('.overlay').fadeOut('slow'); 
    });

    $('.overlay').on('click', function () {
        $(modal).fadeOut('slow');
        $(this).fadeOut('slow');
    });

    $(modal).find('.btn-order').on('click', function () {
        const targetModal = $(this).attr('data-modal');
        $(modal).fadeOut('slow', function () {
            $(`#${targetModal}`).fadeIn('slow');
        });
    });

};




export default popup;