import $ from 'jquery';

const select = () => {

    $(".modal-verification__counter").each(function () {
        var selectElement = $(this);
        
        // Добавляем опцию с начальным значением 0
        var initialOption = $("<option></option>").attr("value", 0).text(0);
        selectElement.append(initialOption);

        for (var i = 1; i <= 100; i++) {
            var option = $("<option></option>").attr("value", i).text(i);
            selectElement.append(option);
        }
    });

};

export default select;
